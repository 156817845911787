import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

import {
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  profileImage: {
    paddingTop: 'calc(30px + 15%)',
    filter: 'blur(3px) contrast(30%) brightness(1.7)',
  },
  profileCard: {
    maxWidth: 960,
    margin: '0 auto',
    textAlign: 'center',
  },
  avatar: {
    width: 100,
    height: 100,
    position: 'relative',
    margin: '-65px auto 20px',

    '& > img': {
      margin: 0,
    },
  },
  smallAvatar: {
    width: 60,
    height: 60,
    position: 'relative',
    margin: '-60px auto 10px',

    '& > img': {
      margin: 0,
    },
  },
  smallText: {
    '& > h5': {
      fontSize: '.7rem',
    },
    '& > h4': {
      fontSize: '.5rem',
    },
  },
}))

const ActorCard = ({
  name,
  handle,
  species,
  image,
  profile,
  sideBySide,
  size,
}) => {
  const classes = useStyles()

  return (
    <Card
      elevation={1}
      className={classes.profileCard}
      component={!profile ? Link : 'div'}
      to={!profile ? `/plant-profile/${handle}` : null}
    >
      <CardMedia className={classes.profileImage} image={image} />
      <CardContent>
        <Avatar
          className={size === 'sm' ? classes.smallAvatar : classes.avatar}
          src={image}
          alt={species}
        />
        <div className={size === 'sm' ? classes.smallText : classes.text}>
          <Typography variant="button" component={profile ? 'h1' : 'h4'}>
            {species}
          </Typography>
          <Typography variant="h6" component={profile ? 'h2' : 'h5'}>
            {name}
          </Typography>
          {size !== 'sm' && (
            <Typography variant="caption" component={profile ? 'h2' : 'h5'}>
              @{handle}
            </Typography>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

ActorCard.propTypes = {
  handle: PropTypes.string.isRequired,
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  profile: PropTypes.bool,
  sideBySide: PropTypes.bool,
  species: PropTypes.string.isRequired,
  size: PropTypes.string,
}

export default ActorCard
