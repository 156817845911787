import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Grid from '@material-ui/core/Grid'
import { Paper, Typography } from '@material-ui/core'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { makeStyles } from '@material-ui/core/styles'
import ActorCard from '../components/actor-card'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 960,
    margin: '0 auto',
  },
  media: {
    height: 200,
  },
  cardGrid: {
    maxWidth: 260,
    minWidth: 180,
  },
  newMembers: {
    maxWidth: 960,
    margin: '20px auto',
    padding: 20,
  },
}))

const PlantsPage = ({
  data: {
    plantsGraph: { PlantActor: plantActors },
  },
}) => {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="Home" />
      <div className={classes.root}>
        <Typography variant="h5" component="h2">
          All Plants
        </Typography>
        <Paper className={classes.newMembers}>
          <Grid container spacing={3} justify="center">
            {plantActors.map(plantActor => (
              <Grid item xs key={plantActor.Name} className={classes.cardGrid}>
                <ActorCard
                  name={plantActor.Name}
                  image={plantActor.ImageSharp.childImageSharp.fixed.src}
                  species={plantActor.instance_of[0].Name}
                  handle={plantActor.Handle}
                  size="sm"
                />
              </Grid>
            ))}
          </Grid>
        </Paper>
      </div>
    </Layout>
  )
}

export default PlantsPage

export const pageQuery = graphql`
  {
    plantsGraph {
      PlantActor(first: 100, orderBy: Name_asc) {
        Name
        Image
        Handle
        instance_of {
          Name
        }
        ImageSharp {
          childImageSharp {
            fixed(cropFocus: CENTER, fit: COVER, width: 120, height: 120) {
              src
            }
          }
        }
      }
    }
  }
`
PlantsPage.propTypes = {
  data: PropTypes.object,
}
